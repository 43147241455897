<template>
  <v-card
    :loading="loading"
  >
    <v-toolbar
      color="grey lighten-2"
      dense
      elevation="0"
    >
      <v-btn
        v-if="hasParent"
        @click.stop="navigateToParent()"
        icon
      >
        <v-icon size="18">mdi-arrow-left</v-icon>
      </v-btn>

      <v-toolbar-title
        class="pl-0 body-1 font-weight-medium"
      >{{ parentFolderName }}</v-toolbar-title>
    </v-toolbar>

    <v-divider></v-divider>

    <v-card-text class="content-container pa-0 ma-0">
      <v-list
        v-if="validEntries.length > 0"
        dense
      >
        <v-list-item-group
          v-model="selectedItem"
          color="primary"
        >
          <v-list-item
            v-for="item in validEntries"
            :key="item.node_id"
            link
          >

            <v-list-item-icon class="mr-4">
              <v-icon color="defaultIconColor">mdi-folder</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title class="text-truncate">{{ item.name }}</v-list-item-title>
            </v-list-item-content>

            <v-list-item-action class="ma-1">
              <v-btn
                @click.stop="navigateTo(item)"
                icon
              >
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </v-list-item-action>

          </v-list-item>
        </v-list-item-group>
      </v-list>

      <v-card-subtitle
        v-else
        align="center"
      >{{ $t('empty') }}</v-card-subtitle>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-spacer></v-spacer>

      <v-btn
        @click.stop="confirmModal()"
        :disabled="selectedItem == null ? true : false"
        color="primary"
        small
      >{{ submitTitle }}</v-btn>
    </v-card-actions>

    <DefaultModal
      :title="(submitTitle == 'Copy' || submitTitle == 'copy')  ? $t('copy_confirm_title') : $t('move_confirm_title')"
      ref="confirmModal"
      @submit="submit()"
      @modal-closed="resetConfirmModal()"
      :submit-button-text="submitTitle"
      :loading="modalLoading"
      :saveButtonDisabled="modalSaveButtonDisabled"
      :alert="modalAlert"
    >
      <template v-slot:content>
        <v-col class="text-body-1">
          <v-row>
           <p class="pre-line"><span class="font-weight-bold">From:</span> {{destination.from}}</p>
          </v-row>
          <v-row>
            <p><span class="font-weight-bold">To:</span> {{destination.to}}</p>
          </v-row>
          <v-row v-if="modalAlert" class="mt-4">
            <v-alert
              dense
              outlined
              :type="modalAlert.type"
            >
              {{modalAlert.message}}
            </v-alert>
          </v-row>
        </v-col>
      </template>
    </DefaultModal>

  </v-card>
</template>

<style scoped>
  .content-container {
    overflow-y: auto;
    max-height: 300px;
    min-height: 150px;
  }

  .pre-line {
    white-space: pre-line;
  }
</style>

<script>

import { mapActions } from 'vuex'
import DefaultModal from '@/components/DefaultModal.vue'

import EntryActions from '@/store/content/entry/actions-types'

export default {
  name: "NavigationModal",

  components:{
    DefaultModal
  },

  props: {
    node: {
      type: Object
    },

    submitTitle: {
      type: String
    },

    selectedItems: {
      type: Array,
      default: () => [],
    },
  },
  mounted() {
    const parentId = this.$route.params.parentId || this.nodeId
    this.fetchData(parentId)
  },

  data() {
    return {
      loading: true,
      items: [],
      breadcrumb: [],
      selectedItem: null,
      destination: {},
      modalLoading: true,
      modalSaveButtonDisabled: true,
      modalAlert: null,
    }
  },

  computed: {
    validEntries() {
      const destinationIds = this.selectedItems.map(destination => destination.entry_fs_id);
      return this.node ? this.items.filter(e =>
        e.node_id !== this.node.node_id &&
        !/^\..*/.test(e.name) &&
        (e.type === "folder" || !e.type) &&
        !destinationIds.includes(e.entry_fs_id)
      ).sort((a, b) => a.name.toUpperCase() < b.name.toUpperCase() ? -1 : 1) : [];
    },

    hasParent() {
      return this.breadcrumb[0] ? true : false
    },

    parentFolderName() {
      return this.breadcrumb[0]?.name || this.$t('home')
    },
  },

  methods: {
    ...mapActions('content/entry', [
      EntryActions.LIST_SUBFOLDER,
      EntryActions.CHECK_COPY_MOVE_POSSIBILITY,
      EntryActions.GET_ENTRY_DATA,
    ]),

    fetchData(parentId) {
      this.loading = true
      this.selectedItem = null

      if (! parentId) {
        this[EntryActions.GET_ENTRY_DATA](this.node.node_id)
          .then((data) => {
            parentId = data.folder_parent_id
            this.listSubFolders(parentId)
          })
          .catch(() => this.loading = false)
      } else {
        this.listSubFolders(parentId)
      }
    },

    listSubFolders(parentId) {
      this[EntryActions.LIST_SUBFOLDER]({parentId, updateList: false})
          .then((response) => {
            this.breadcrumb = [...response.breadcrumb]

            this.items = [...response.entries]

            this.loading = false
          })
          .catch(() => this.loading = false)
    },

    navigateTo(item) {
      this.fetchData(item.node_id)
    },

    navigateToParent() {
      const parent = this.breadcrumb[1]

      this.fetchData(parent?.node_id)
    },

    submit() {
      const nodeId = this.validEntries[this.selectedItem]?.node_id

      this.$emit('chosen-directory', nodeId)
    },

    confirmModal() {
      const selectedItemNode = this.validEntries[this.selectedItem];

      if (Array.isArray(this.selectedItems) && this.selectedItems.length > 0) {
        this.destination.from = '\n' + this.selectedItems.map(i => i.entry_fs_id).join(',\n');
      } else {
        this.destination.from = this.node.entry_fs_id;
      }

      this.destination.to = selectedItemNode.node_parent === null ? `/${selectedItemNode.name}` : selectedItemNode.entry_fs_id;

      this.resetConfirmModal()
      this.$refs.confirmModal.openModal()

      let nodesIds = [this.node.node_id]
      nodesIds = [...new Set([...nodesIds, ...this.selectedItems.map(i => i.node_id)])]

      const data = {
        "destination_id": selectedItemNode.node_id,
        "action_key": this.submitTitle.toLowerCase(),
        "nodes_ids": nodesIds
      }

      var canContinue = true;

      this[EntryActions.CHECK_COPY_MOVE_POSSIBILITY](data)
        .then(response => {
          this.modalLoading = false;

          response.forEach(res => {
            if (!res.node_name_already_exists) {
              return
            }

            if (data.action_key === "copy") {
              this.modalAlert = { type: "warning", message: this.$t('copy_confirm_alert') }
            }

            if (data.action_key === "move") {
              canContinue = false;
              this.modalAlert = { type: "error", message: this.$t('move_confirm_alert') }
            }
          })
        })
        .catch(() => this.loading = false)
        .finally(() => this.modalSaveButtonDisabled = !canContinue)
    },

    resetConfirmModal() {
      this.modalLoading = true;
      this.modalSaveButtonDisabled = true;
      this.modalAlert = null;
    },

    closeModal() {
      this.$refs.confirmModal.closeModal()
    }
  },
  watch: {
    selectedItems(newList) {
      this.selectedItems = newList
      if (newList.length == 0) {
        this.resetConfirmModal()
      }
    },
    validEntries(newEntries) {
      if (newEntries.length == 0) {
        this.selectedItem = null
      }
    }
  },
}

</script>
